import PerfectScrollbar from 'perfect-scrollbar';
import $ from "jquery";

$(function() {

    $('.photo-gallery ul li').click(function() {
        var src = $(this).data('src');
        var $gallery = $(this).closest('.photo-gallery');
        $gallery.find('.current-photo img').attr('src', src);
    });

    $('.photo-gallery').each(function() {
        $(this).find('li')[0].click();
    });

    // $(".product-gallery").productGallery();
});
$(document).ready(function () {

    $(".photo-gallery ul li .thumbnail").click(function () {
        $(".photo-gallery ul li .thumbnail").removeClass("active");
        $(this).addClass("active");
    })

    $('.carousel').carousel({
        interval: 2500,
        touch: true
    });

    const el = document.querySelector("header")
    const placeholder = document.querySelector("#header-placeholder");

    const observer = new IntersectionObserver(
        ([e]) => {
            // Add or remove the "fixed" class
            const isIntersecting = e.intersectionRatio < 1;
            e.target.classList.toggle("fixed", isIntersecting);

            // Set the height of the placeholder when header is fixed
            if(isIntersecting){
                placeholder.style.height = `${el.getBoundingClientRect().height}px`;
            } else {
                placeholder.style.height = "auto";
            }
        },
        {
            threshold: [1],
            rootMargin: '-1px 0px 0px 0px',
        },
    );

    observer.observe(el);


    // const el = document.querySelector("header")
    // const observer = new IntersectionObserver(
    //     ([e]) => e.target.classList.toggle("fixed", e.intersectionRatio < 1),
    //     {
    //         threshold: [1],
    //         rootMargin: '-1px 0px 0px 0px',
    //     },
    // );
    //
    // observer.observe(el);

   // let initialOffset = $('header').offset().top;

    // $(window).scroll(function () {
    //     var sticky = $('header'),
    //         scroll = $(window).scrollTop();
    //     let offset = $('header').offset().top;
    //
    //     if (scroll >= offset && scroll >= initialOffset)
    //         sticky.addClass('fixed');
    //     else
    //         sticky.removeClass('fixed');
    //     $(".christmas-header").removeClass('fixed')
    // });


    $('.intro-wine-tests .owl-carousel').owlCarousel({
        loop:true,
        nav:false,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    })

    $('.owl-carousel.mystery-box-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        autoHeight: true,
        responsive:{
            0:{
                items:1,
                stagePadding: 20,
            },
            400:{
                items:1,
                stagePadding: 30,
            },
            500:{
                items:1,
                stagePadding: 80,
            },
            600:{
                items:1,
                stagePadding: 150,
            },
            700:{
                items:1,
                stagePadding: 150,
            }
        }
    })

//    $('#activate-gift-card').click(function () {
//        $(".gift-card-action").toggle(this.checked);
//    });
//
//    $('#activate-for-gift').click(function () {
//        $(".mystery-box-order, .checkout").toggleClass('for-gift', this.checked);
//    });

    $('.countries h3').click(function (e) {
        $('.countries .countries-result').slideToggle(function () {
            $('.countries').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.sort h3').click(function (e) {
        $('.sort .sort-result').slideToggle(function () {
            $('.sort').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.sidebar h3, .toggle-filter').click(function (e) {
        $('.sidebar .widgets').slideToggle(function () {
            $('.sidebar').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.widget h4').click(function (e) {
        $(this).siblings('.widget-box').slideToggle(function () {
            $(this).closest('.widget').toggleClass('widget-active');
        });
        e.preventDefault();
    });

    $('.aside-dropdown .dd-menu > a').click(function (e) {
        $(this).siblings('ul').slideToggle(function () {
            $(this).parent().toggleClass('dd-menu-active');
        });
        e.preventDefault();
    });

    $('.search-widget').click(function () {
        $('.search').toggleClass('open');
    });

    $('.nav-btn').click(function () {
        $(this).toggleClass('open');
        $('nav').toggleClass('open');
    });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('.dropdown-btn').click(function () {
            $(this).siblings('ul, .mega-menu').slideToggle();
            $(this).find('.fa').toggleClass('fa-angle-down fa-angle-up');
        });
    } else {
        $('.menu > li').hover(function () {
            $(this).find('.mega-menu, .sub-menu').first().stop().slideDown(200);
        }, function () {
            $(this).find('.mega-menu, .sub-menu').stop().slideUp(200);
        });
    }



    if ($('.owl-testimonials').length) {

        $('.owl-testimonials').owlCarousel({
            loop: true,
            margin: 0,
            responsiveClass: true,
            dots: true,
            navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                600: {
                    items: 1,
                    nav: false
                },
                1000: {
                    items: 1,
                    nav: false
                },
                1600: {
                    items: 1,
                    nav: false
                }
            }
        });
    }

    let productsCarousel = $('.owl-products').owlCarousel({
        loop: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        stagePadding: 150,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive: {
            0: {
                items: 1,
                nav: false,
                stagePadding: 80
            },
            600: {
                items: 2,
                nav: false,
                stagePadding: 80
            },
            770: {
                items: 3,
                nav: true,
                stagePadding: 80
            },
            1200: {
                items: 4,
                nav: true,
                stagePadding: 80
            },
            1600: {
                items: 6,
                nav: true
            }
        }
    });

    $('.mystery-box-comments.owl-carousel').owlCarousel({
        loop:true,
        margin:25,
        nav:true,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive:{
            0:{
                items:1,
                stagePadding: 25,
                margin: 10
            },
            575:{
                items:1,
                stagePadding: 150
            },
            768:{
                items:2,
                stagePadding: 40
            },
            1024:{
                items:2,
                stagePadding: 120
            },
            1200:{
                items:3
            }
        }
    })




    const throttleFunction = (func, delay) => {

        // Previously called time of the function
        let prev = 0;
        return (...args) => {
            // Current called time of the function
            let now = new Date().getTime();

            // Logging the difference between previously
            // called and current called timings

            // If difference is greater than delay call
            // the function again.
            if (now - prev > delay) {
                prev = now;

                // "..." is the spread operator here
                // returning the function with the
                // array of arguments
                return func(...args);
            }
        }
    }

    function triggerOnScroll(owl, e) {
        let deltaX = e.originalEvent.deltaX;
        let deltaY = e.originalEvent.deltaY;

        if (deltaY <= -1 || deltaY >= 1) {
            return;
        }
        if (deltaX > 0) {
            productsCarousel.trigger('next.owl');
        } else {
            productsCarousel.trigger('prev.owl');
        }
        e.preventDefault();
    }

    productsCarousel.on('wheel', '.owl-stage', throttleFunction((e) => {
        triggerOnScroll(productsCarousel, e);
    }, 1000));

    $('.owl-products-no-clone').owlCarousel({
        loop: false,
        rewind: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        stagePadding: 150,
        navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
        responsive: {
            0: {
                items: 1,
                nav: false,
                stagePadding: 80
            },
            600: {
                items: 2,
                nav: false,
                stagePadding: 80
            },
            770: {
                items: 3,
                nav: true,
                stagePadding: 80
            },
            1200: {
                items: 4,
                nav: true,
                stagePadding: 80
            },
            1600: {
                items: 6,
                nav: true
            }
        }
    });



    $('.slider-module .owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    })


    $('.product-slide-module .owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                stagePadding: 30,
                nav: false,
                items:1
            },
            426:{
                stagePadding: 30,
                nav: false,
                items:2
            },
            660:{
                stagePadding: 40,
                nav: false,
                items:3
            },
            767:{
                items:3
            },
            796:{
                items:4
            },
            1150:{
                items:5
            },
            1316:{
                items:6
            }
        }
    })


    $('.slide-offer .owl-carousel').owlCarousel({

        loop:false,
        margin:10,
        nav:false,
        responsive:{
            0:{
                stagePadding: 50,
                items:1
            },
            450:{
                stagePadding: 20,
                items:2
            },
            580:{
                stagePadding: 30,
                items:3
            }
        }
    })

    $('.loyalty-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:false,
        stagePadding: 65,
        responsive:{
            0:{
                items:1,
                stagePadding: 50,
            },
            380:{
                items:1,
                stagePadding: 60,
            },
            575:{
                items:2,
                stagePadding: 55,
            },
            768:{
                items:2,
                stagePadding: 100
            }
        }
    })

//questionnaire radio
    $('.christmas-landing.questionnaire form .form-group label.radio-label').click(function () {
        $(".christmas-landing.questionnaire form .form-group label.radio-label").removeClass("active")
        $(this).addClass("active")

    })

    //questionnaire checkbox

    $(".questionnaire form .form-group .checkbox-label").click(function () {
        $('[name="checkbox1"]').change(function() {
            if ($(this).is(':checked')) {
                $(this).parent().addClass("active")
            }
            else {
                $(this).parent().removeClass("active")
            }
        });
    });


    $(".intro-bg .download-btn").click(function () {
        const textElement = document.getElementById("form-offset")
        textElement.scrollIntoView()
    })


    $(".scroll-reviews").click(function (e) {
        scrollPos = $('.comments').offset().top - 50;
        scrollTo(scrollPos, 2000);

        e.preventDefault();
    });

    const container = document.querySelector('.filter-list, .cart-result');
    if (container) {
        const ps = new PerfectScrollbar(container);

        $(".cart-result").hover(function () {
            ps.update();
        });

    }

});


Object.defineProperty(HTMLMediaElement.prototype, 'introVideo', {
    get: function () {
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
});


// scrollTop Y function
function scrollTo(position, speed) {

    speed = (typeof speed === "undefined") ? 500 : speed;

    $('html, body').animate({
        scrollTop: position
    }, speed);
}