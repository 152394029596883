import Vue from 'vue/dist/vue.esm.js';

Vue.component('sumup-embedded', {
    template: '#sumup-embedded',
    delimiters: ['[[', ']]'],
    props: {
        hasUser: {
            required: true,
            type: Number,
        },
        locale: {
            required: true,
            type: String,
        },
        updateDataUrl: {
            required: true,
            type: String,
        },
        saveUrl: {
            required: true,
            type: String,
        },
        checkoutId: {
            required: true,
            type: String,
        },
        customerId: {
            required: false,
            type: String,
            default: null
        },
        clientId: {
            required: false,
            type: String,
            default: null
        },
        userIp: {
            required: false,
            type: String,
            default: null
        },
        captureUrl: {
            required: true,
            type: String,
        },
        genericError: {
            required: true,
            type: String,
        },
        cardError: {
            required: true,
            type: String,
        },
    },

    created() {
        //
    },

    data() {
        return {
            saveCheckoutId: null,
            saveCaptureUrl: null,
            saveUpdateUrl: null,
            errorMsg: null,
            formData: {
                cardName: '',
                cardNumber: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: '',
                saveCard: false
            },
            isLoading: false,
        }
    },
    methods: {

        async postData(url = '', data = {}) {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
            return response.json();
        },
        async postWithAuthData(url = '', data = {}) {
            const response = await fetch(url, {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + btoa(this.clientId + ':'),
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(data)
                })
            ;
            return response.json();
        },
        async putData(url = '', data = {}) {
            const response = await fetch(url, {
                method: 'PUT',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
            return response.json();
        },

        normalizeFormData() {

            let key = 'payment_type';

            let data = {
                card: {
                    "name": this.formData.cardName,
                    "number": this.formData.cardNumberNotMask.split(' ').join(''),
                    "expiry_month": this.formData.cardMonth,
                    "expiry_year": String(this.formData.cardYear).slice(2, 4),
                    "cvv": this.formData.cardCvv
                }
            }

            data[key] = 'card';

            if (this.formData.saveCard) {
                data['mandate'] = {
                    "type": "recurrent",
                    "user_agent": navigator.userAgent,
                    "user_ip": this.userIp
                }

            }

            return data;
        },

        onFormSubmitted() {
            this.errorMsg = null;

            let _self = this;
            _self.isLoading = true;

            if (this.formData.saveCard && !this.saveCheckoutId) {
                _self.postData(this.saveUrl).then(function (response) {
                    _self.saveCheckoutId = response.checkoutId;
                    _self.saveCaptureUrl = response.captureUrl;
                    _self.saveUpdateUrl = response.updateUrl;
                    _self.proceedCheckout();
                })

            } else {
                this.proceedCheckout();
            }


        },

        proceedCheckout() {
            let _self = this;
            let data = this.normalizeFormData();

            let checkoutId = this.formData.saveCard ? this.saveCheckoutId : this.checkoutId;
            let updateUrl = this.formData.saveCard ? this.saveUpdateUrl : this.updateDataUrl;

            _self.putData("https://api.sumup.com/v0.1/checkouts/" + checkoutId, data).then(function (response) {
                _self.postData(updateUrl, response);
                if (_self.validateResponse(response)) {

                    if ("next_step" in response) {
                        _self.buildHtmlForm(response);
                    } else {
                        _self.paymentCompleted(response);
                    }
                } else {
                    _self.isLoading = false;
                }

            });
        },


        buildHtmlForm(response) {

            let data = response.next_step;

            let form = document.createElement('form');
            form.method = data.method;
            form.action = data.url;

            let payload = Object.entries(data.payload || {}) ;
            for (const [key, value] of payload) {
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }

            document.body.append(form);
            form.submit();
        },
        paymentCompleted(data) {
            let _self = this;
            this.isLoading = true;

            data.checkoutId = this.checkoutId;
            data.customerId = this.customerId;

            let captureUrl = this.formData.saveCard ? this.saveCaptureUrl : this.captureUrl;

            _self.postData(captureUrl, data).then(function (response) {

                if (response.success && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            });

        },

        validateResponse(response) {
            if (Array.isArray(response)) {
                this.errorMsg = this.cardError;
                return false;
            }

            if (response.error_code) {
                if (response.error_code === 'BAD_REQUEST' || response.error_code === 'INVALID') {
                    this.errorMsg = this.cardError;
                } else if (response.error_code === 'CHECKOUT_PROCESSED') {
                    //TODO: what happens here? is that actualy possible?
                } else {
                    this.errorMsg = this.genericError;
                }
                return false;
            }
            return true;
        },

        onSuccess(data) {
            let _self = this;
            _self.postData(_self.captureUrl, data).then(function (response) {

                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
                _self.isLoading = false;
            });
        },
        onError(data) {
            let _self = this;
            _self.postData(_self.updateDataUrl, data);
        },

    }
});