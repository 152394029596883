import Vue from 'vue/dist/vue.esm.js';

Vue.component('christmas-gift-finder-price-filter', {
    template: `#christmas-gift-finder-price-filter`,
    delimiters: ['[[', ']]'],
    props: {
        priceOptions: {
            required: true,
            type: Array
        },
    },
    updated() {
    },

    beforeMount() {
        this.selectedOption = null;
    },

    data() {
        return {
            selectedOption: this.selectedOption,
        }

    },

    methods: {
        selectPriceOption(option) {
            this.selectedOption = option;
        }
    },

    computed: {
        isNextStageDisabled() {
            return this.selectedOption === null;
        },
        nextStageHref() {
            if (this.selectedOption !== null) {
                return this.selectedOption.url;
            }
            return 'javascript:void(0)';
        }
    }
});