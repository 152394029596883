import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
    }

    submitForm(submitEvent) {
        submitEvent.preventDefault();

        let container = $("#subscription-form");

        let formData = [];

        if (container) {
            let giveAwayForm = $('form', container);
            if (!giveAwayForm[0].checkValidity()) {
                giveAwayForm[0].reportValidity();
                return;
            }

            formData = new FormData(giveAwayForm[0]);

            let _self = this;
            $.ajax({
                url: _self.formUrl,
                data: formData,
                method: 'POST',
                processData: false,
                contentType: false,
                success: function (response) {
                    var el = document.createElement('html');
                    el.innerHTML = response;
                    let form = el.querySelector('#replaceDiv');
                    $('#replaceDiv').replaceWith(form);

                    let flashMessage = $("#giveaway-flash");
                    if (flashMessage.length > 0) {
                        $('html, body').animate({ scrollTop: flashMessage.offset().top - $(window).height()/2 }, 500);
                    }

                },
                error: function (error) {
                    console.log(error);
                }
            });

        }
        submitEvent.returnValue = true;
    }
}

