import Vue from 'vue/dist/vue.esm.js';

Vue.component('christmas-gift-finder-category-filter', {
    template: `#christmas-gift-finder-category-filter`,
    delimiters: ['[[', ']]'],
    props: {
        groups: {
            required: true,
            type: Array
        },
        nextStageUrl: {
            required: true,
            type: String,
        }
    },

    updated() {
    },

    beforeMount() {
    },

    data() {
        return {
            sGroups: this.groups,
        }

    },

    methods: {
        changeCategoryOption(option) {
            let fMessage = 'Не можеш да комбинираш избрания тип филтър';
            option.isActive = !option.isActive;

            //При селектирване на опция
            if (option.isActive === true) {
                /**
                 * Ако опцията може да се комбинира с други, означава че е категория и че всички, които не могат да се комбинират,
                 * трябва да се направят неактивни и да им излиза съобщение, че не могат да се комбинират с избрания филтър.
                 * В противан случай, ако опцията не може да се комбинирва с други, означава че всички други трябва да се
                 * направят неактивни и да им се извади съобщение, че не могат да се комбинират с избраното,
                 * естествено ако вече нямат съобщение че са в друг ценови диапазон.
                 *
                 */
                if (option.isCombinable) {
                    $.each(this.sGroups, function (key, value) {
                        $.each(value.options, function (oK, oV) {
                            if (oV.id !== option.id && oV.isCombinable === false && oV.message === null) {
                                oV.message = fMessage;
                            }
                        })
                    });
                } else {
                    $.each(this.sGroups, function (key, value) {
                        $.each(value.options, function (oK, oV) {
                            if (oV.id !== option.id && oV.message === null) {
                                oV.message = fMessage;
                            }
                        })
                    });
                }
            }
            /**
             * При деселектиране на опция
             * Проверяваме дали всички останали опции са деактивирани,
             * и ако са махаме от останалите съобщението че не може да се комбинира.
             */
            else {
                let haveActive = false;
                $.each(this.sGroups, function (key, value) {
                    $.each(value.options, function (oK, oV) {
                        if (oV.id !== option.id && oV.isActive) {
                            haveActive = true;
                        }
                    })
                });

                if (!haveActive) {
                    $.each(this.sGroups, function (key, value) {
                        $.each(value.options, function (oK, oV) {
                            if (oV.id !== option.id && oV.message === fMessage) {
                                oV.message = null;
                            }
                        })
                    });
                }
            }
        }
    },

    computed: {
        isNextStageDisabled() {
            let haveActive = false;

            $.each(this.sGroups, function (key, value) {
                $.each(value.options, function (oK, oV) {
                    if (oV.isActive) {
                        haveActive = true;
                    }
                })
            });

            return !haveActive;
        },
        nextStageHref() {
            /**
             * Ако избраната опция има url тогава вземаме него за линк на бутона
             * Ако избраната/избраните опции имат categoryId слагаме тях за query параметър на урл-а.
             * И ако няма избрана опция 'javascript:void(0)'
             */
            let url = null;
            let categoryIds = [];

            $.each(this.sGroups, function (key, value) {
                $.each(value.options, function (oK, oV) {
                    if (oV.isActive) {
                        if (oV.url !== null) {
                            url = oV.url;
                        } else if (oV.categoryId) {
                            categoryIds.push(oV.categoryId);
                        }
                    }
                })
            });

            //Сглобяваме урл-а с ид-тата на категориите ако има категории.
            if (categoryIds.length > 0) {
                url = this.nextStageUrl + "?categoryIds=";
                $.each(categoryIds, function (cK, cV) {
                    if (cK > 0)
                        url += ",";
                    url += cV;
                })
            }

            if (url !== null)
                return url;
            return 'javascript:void(0)';
        }
    }
});