import Vue from 'vue/dist/vue.esm.js';

Vue.component('sumup-save-card', {
    template: '#sumup-save-card',
    delimiters: ['[[', ']]'],
    props: {
        hasUser: {
            required: true,
            type: Number,
        },
        locale: {
            required: true,
            type: String,
        },
        customerId: {
            required: false,
            type: String,
            default: null
        },
        checkoutId: {
            required: false,
            type: String,
            default: null
        },
        clientId: {
            required: false,
            type: String,
            default: null
        },
        userIp: {
            required: false,
            type: String,
            default: null
        },
        captureUrl: {
            required: true,
            type: String,
        },
        genericError: {
            required: true,
            type: String,
        },
        cardError: {
            required: true,
            type: String,
        },
    },

    created() {
        //
    },

    data() {
        return {
            errorMsg: null,
            formData: {
                cardName: '',
                cardNumber: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: '',
                saveCard: false
            },
            isLoading: false,
        }
    },
    methods: {

        async postData(url = '', data = {}) {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            });
            return response.json();
        },
        async postWithAuthData(url = '', data = {}) {
            const response = await fetch(url, {
                    method: 'PUT',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': 'Basic ' + btoa(this.clientId + ':'),
                        'Authorization': 'Bearer ' + this.clientId,
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(data)
                })
            ;
            return response.json();
        },

        normalizeFormData() {


            let data = {
                payment_type: 'card',
                installments: 1,
                card: {
                    "name": this.formData.cardName,
                    "number": this.formData.cardNumberNotMask.split(' ').join(''),
                    "expiry_month": this.formData.cardMonth,
                    "expiry_year": String(this.formData.cardYear).slice(2, 4),
                    "cvv": this.formData.cardCvv
                },
                mandate: {
                    "type": "recurrent",
                    "user_agent": navigator.userAgent,
                    "user_ip": this.userIp
                }
            }

            return data;
        },

        onFormSubmitted() {
            this.errorMsg = null;
            this.isLoading = true;
            this.proceedSaveCard();

        },
        proceedSaveCard() {

            let _self = this;
            let data = this.normalizeFormData();

            _self.postWithAuthData("https://api.sumup.com/v0.1/checkouts/" + this.checkoutId, data).then(function (response) {
                if (_self.validateResponse(response)) {
                    if ("next_step" in response) {
                        _self.buildHtmlForm(response);
                    } else {
                        _self.paymentCompleted(response);
                    }
                } else {
                    _self.isLoading = false;
                }
            });
        },


        buildHtmlForm(response) {

            let data = response.next_step;

            let form = document.createElement('form');
            form.method = data.method;
            form.action = data.url;


            for (const [key, value] of Object.entries(data.payload)) {
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }

            document.body.append(form);
            form.submit();
        },

        paymentCompleted(data) {
            let _self = this;
            this.isLoading = true;

            data.customerId = this.customerId;
            _self.postData(this.captureUrl, data).then(function (response) {

                if (response.success && response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
            });

        },

        validateResponse(response) {
            if (Array.isArray(response)) {
                this.errorMsg = this.cardError;
                return false;
            }

            if (response.error_code) {
                if (response.error_code === 'BAD_REQUEST' || response.error_code === 'INVALID') {
                    this.errorMsg = this.cardError;
                } else if (response.error_code === 'CHECKOUT_PROCESSED') {
                    //TODO: what happens here? is that actualy possible?
                } else {
                    this.errorMsg = this.genericError;
                }
                return false;
            }
            return true;
        },
    }
});