import Vue from 'vue/dist/vue.esm.js';

Vue.component('cart-widget', {
    template: `#cart-widget`,
    delimiters: ['[[', ']]'],
    props: {
        initialCart: {
            required: true,
            type: Object,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },
        removeCartRowUrl: {
            required: true,
            type: String,
        }
    },

    mounted() {
        EventManager.listen('item-added', this.cartUpdated);
        EventManager.listen('item-removed', this.cartUpdated);
    },

    data() {
        return {
            cart: this.initialCart,
        }
    },

    methods: {
        cartUpdated(data) {
            this.cart = JSON.parse(data);
        },

        removeRow(rowId, removeItemUrl) {
            this.buildAjax(rowId, removeItemUrl);
        },

        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, {style: 'currency', currency: this.currency}).format(price);
        },

        buildAjax(rowId, url) {
            let _self = this
            $.ajax({
                data: {id: rowId},
                url: url,
                method: 'POST',
                success: function (result) {
                    _self.successAjax(result)
                },
            });
        },

        successAjax(result) {
            if (result.success === false) {
                return;
            }

            EventManager.fire('item-removed', result.cart);

            let event = {
                event: 'remove_from_cart',
                eventData: result.variant,
                action: 'CartWidget',
            }
            EventManager.fire('gtm-event',event);
        },
        concatenateAttributeValues(attributes) {
            let str = '';
            for (let [index,attribute] of attributes.entries()) {
                if(index !== 0){
                    str += ', '
                }
                if(attribute.showUnit){
                    str += attribute.unit + ' x '
                }
                str +=  ' ' + attribute.value;

            }
            return str;
        }
    },

    computed: {
        total() {
            return this.formattedPrice(this.cart.total)
        }
    }
});