import {Controller} from "stimulus"


export default class extends Controller {
    connect() {
        const script = document.createElement('script');
        script.src = this.element.dataset.code;
        script.async = true;
        this.element.appendChild(script);
    }
}