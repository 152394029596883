import {Controller} from "stimulus";
import 'select2/dist/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
import 'select2';
import 'select2/dist/js/i18n/bg';

export default class extends Controller {
    initialize() {
        let language = document.documentElement.lang;

        this.ajaxUrl = this.element.getAttribute("data-ajax-url");
        this.eventOnSelect = this.element.getAttribute("data-event-on-select");
        this.maximumSelectionLength = this.element.getAttribute("data-maximum-selection-length");
        this.params = {
            theme: 'bootstrap4',
            language: language,
        };

        if (this.maximumSelectionLength !== null) {
            this.params.maximumSelectionLength = 3;
        }
        if (this.ajaxUrl !== null) {
            this.params = {

                theme: 'bootstrap4',
                language: language,
                minimumInputLength: 3,
                tags: false,
                ajax: {
                    url: this.ajaxUrl,
                    dataType: 'json',
                    type: "GET",
                    quietMillis: 50,
                    createTag: function (params) {
                        return undefined;
                    },
                    data: function (params) {

                        let queryParameters = {
                            term: params.term
                        };

                        return queryParameters;
                    },
                    processResults: function (data) {
                        return {
                            results: $.map(data, function (item) {
                                return {
                                    text: item.name + ' ( ' + item.code + ' / ' + item.subCode + ' )',
                                    id: item.id
                                }
                            })
                        };
                    }
                }
            };
        }
    }

    connect() {

        $(this.element).select2(this.params);

        let _self = this;

        if (this.eventOnSelect) {
            $(this.element).on("select2:select", function (e) {
                EventManager.fire(_self.eventOnSelect, e.params.data.id)
            });
        }
    }
}