import Vue from 'vue/dist/vue.esm.js';
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm';
import {bg, en} from 'vuejs-datepicker/dist/locale'

Vue.component('datepicker', Datepicker);

Vue.component('mystery-box-payment-methods', {
    template: `#mystery-box-payment-methods`,
    delimiters: ['[[', ']]'],
    props: {
        paymentMethods: {
            required: true,
            type: Array,
        },
        hasUser: {
            required: true,
            type: Number,
        },
        userCards: {
            required: false,
            type: Array,
        },
        periodType: {
            required: true,
            type: String,
        },
        isGift: {
            required: true,
            type: String
        }
    },

    mounted() {
        this.initHasCard();

        if (parseInt(this.$refs.selectedPaymentMethod.dataset.val)) {
            this.selectPaymentMethod(this.paymentMethods.filter(d => d.id === parseInt(this.$refs.selectedPaymentMethod.dataset.val))[0]);
        }

        if (parseInt(this.$refs.selectedCard.dataset.val)) {
            this.selectCard(this.userCards.filter(d => d.id === parseInt(this.$refs.selectedCard.dataset.val))[0]);
        }

        EventManager.listen('load-stimulus', this.loadStimulus);
    },


    data() {
        return {
            selectedPaymentMethod: null,
            selectedCard: null,
            hasCard: 0,
        }
    },

    methods: {
        loadStimulus() {
        },
        initHasCard() {
            let value;
            if (this.validateRef("hasCard")) {
                if (isNaN(parseInt(this.$refs.hasCard.dataset.val))) {
                    value = null;
                } else {
                    value = parseInt(this.$refs.hasCard.dataset.val);
                }
            }
            this.hasCard = value;
        },

        validateRef(ref) {
            return this.$refs[ref] !== undefined;
        },

        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        },

        selectPaymentMethod(paymentMethod) {

            if (paymentMethod ===
                this.selectedPaymentMethod) {
                return;
            }
            this.selectedPaymentMethod = paymentMethod;
            this.selectedCard = null;

            if (paymentMethod.method === 'credit_card' && this.userCards.length > 1) {
                this.selectCard(this.userCards.filter(d => d.isPrimary === true)[0]);
            }
        },
        selectCard(card) {
            if (card === this.selectedCard) {
                return;
            }
            this.selectedCard = card;
        },
        toggleHasCard() {
            this.hasCard = !this.hasCard & 1;
        },

        getAvailableMethods() {
            if (this.isGift === "1") {
                return this.paymentMethods.filter(pm => pm.isAvailableForGift === true);
            } else {
                if (this.periodType === 'recurring') {
                    return this.paymentMethods.filter(pm => pm.method !== 'bank_transfer' && pm.method !== 'credit_card');
                } else {
                    return this.paymentMethods.filter(pm => pm.method !== 'recurring' && pm.isAvailableForGift === true);
                }

            }
        },
    },

    computed: {
        paymentMethodComputed() {
            if (this.selectedPaymentMethod) {
                return this.selectedPaymentMethod.id;
            }
            return null;
        },
    }
});