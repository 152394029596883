import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
    connect() {
        this.startPosition = this.element.dataset.startPosition

        $(window).scroll(function () {
            var sticky = $('header'),
                scroll = $(window).scrollTop();

            if (scroll >= 100)
                sticky.addClass('fixed');
            else
                sticky.removeClass('fixed');
            $(".christmas-header").removeClass('fixed')
        });
        let _self = this;
        $(document).ready(function () {
            $('.nav-btn').click(function () {
                $(this).toggleClass('open');
                $('nav').toggleClass('open');
                $(".belgian-header .btn-cont").toggleClass("open");
            });

            $(".belgian-product-slider .owl-thumbs").hover(function () {
                $(".product-slider-block .product-bottle").toggleClass("hovered");
            })

            $('.belgian-carousel.owl-carousel').owlCarousel({
                loop:true,
                margin:0,
                nav:false,
                autoHeight: true,
                startPosition: _self.startPosition,
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:1
                    },
                    1000:{
                        items:1
                    }
                }
            })

            $('.belgian-product-slider.owl-carousel').owlCarousel({
                loop:true,
                margin:0,
                nav:true,
                center: true,
                startPosition: _self.startPosition,
                autoHeight: true,
                thumbs: true,
                thumbsPrerendered: true,
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:1
                    },
                    1000:{
                        items:1
                    }
                }
            });
        })
    }
}