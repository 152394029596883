import Vue from 'vue/dist/vue.esm.js';
import {bg, en} from 'vuejs-datepicker/dist/locale'

Vue.component('sommeliers', {
    template: `#sommeliers`,
    delimiters: ['[[', ']]'],
    props: {
        sommeliers: {
            required: true,
            type: Array,
        },
        selectSommelierUrl: {
            required: true,
            type: String,
        }
    },

    mounted() {
        console.log("daaa");
    },

    data() {
        return {
            selectedSommelier: null,
            isSent: false,
        }
    },

    methods: {
        selectSommelier(sommelier) {
            if (this.selectedSommelier == null || this.selectedSommelier !== sommelier) {
                this.selectedSommelier = sommelier;
            }
        },
        submitSommelier() {
            let _self = this;
            console.log({sommelierId: _self.selectedSommelier.id});
            $.ajax({
                data: {sommelierId: _self.selectedSommelier.id},
                url: _self.selectSommelierUrl,
                method: 'POST',
                success: function (result) {
                    _self.isSent = true;
                },
            });
        },
    },
});